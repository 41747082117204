@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
	html { font-size: 16px; height: 100%; }
	body { margin: 0; height: 100%; background-color: '#F7F7F4'; }
	blockquote { margin: -1rem 2rem; padding: 0; }
	p { margin: 0; }
	ul { margin: -1rem 0; }
	li { list-style-type: disc; margin: -1rem 2rem;}
}