@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }  
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }  
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}

@include keyframes(fade-in) {
	0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

@include keyframes(slow-fade) {
	0% { opacity: 0; }
  100% { opacity: 1; }
}


.slow-fade {
	@include animation('slow-fade 2s')
}
.h-s {
	@include animation('fade-in 0.5s')
}
.o-s {
	@include animation('fade-in 1s')
}
.oh-s {
	@include animation('fade-in 1.5s')
}
.t-s {
	@include animation('fade-in 2s')
}